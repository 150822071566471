// import '~/packages/datakode/nuxt-datakode-utils/plugins/translation.ts'

export default {
    mesintentions: 'Mes intentions',
    mesintentionsarchivees: 'Mes intentions réalisées ou abandonnées',
    touteslesintentions: 'Toutes les intentions',
    touteslesintentionsarchivees: 'Toutes les intentions réalisées ou abandonnées',
    mesopportunites: 'Mes opportunités',
    touteslesopportunites: 'Toutes les opportunités',
    intentionsimport: 'Import des intentions',
    id: 'Id',
    commune: {
        t: 'Commune',
    },
    name: 'nom',
    sessions: {
        add: 'Ajouter une session',
        delete: {
            index: 'Supprimer',
            text: 'Êtes vous sur de vouloir supprimer cette session ?',
            title: 'Supprimer session',
        },
        edit: 'Modifier la session',
        end_date: 'Date de fin',
        index: 'Liste des sessions',
        name: 'Nom',
        start_date: 'Date de début',
        status: {
            current: 'En cours',
            future: 'À venir',
            index: 'Statut',
            past: 'Terminé',
        },
        toaster: {
            DELETE: {
                success: {
                    text: '',
                    title: 'La session a été supprimée avec succès.',
                },
            },
            PUT: {
                success: {
                    text: '',
                    title: 'La session a été modifiée avec succès.',
                },
            },
            POST: {
                success: {
                    text: '',
                    title: 'La session a été ajoutée avec succès.',
                },
            },
        },
        view: 'Visualiser la session',
    },
    voirie: {
        likes: {
            toaster: {
                POST: { success: { title: 'Réponse enregistrée', text: '' } },
                DELETE: { success: { title: 'Réponse supprimée', text: '' } },
            },
        },
        like: {
            credentials: {
                created_at: 'Date de réponse',
                created_by: {
                    name: 'Réponse par',
                },
            },
        },
        comments: {
            toaster: {
                POST: { success: { title: '', text: '' } },
                DELETE: { success: { title: '', text: '' } },
            },
        },
        intentions: {
            add: 'Ajouter l\'intention',
            export: 'Exporter',
            voie: 'Nom de la voie',
            ban_street: 'Nom de la voie',
            cancel: 'Annuler',
            commune: {
                index: 'Commune',
                insee: 'Commune',
            },
            insee: 'Commune',
            contact_email: 'Email',
            contact_name: 'Nom',
            contact_nom: 'Nom',
            contact_phone: 'Téléphone',
            contact_tel: 'Téléphone',
            custom_column: '',

            delete: {
                index: 'Supprimer l\'intention',
                text: 'Souhaitez-vous vraiment supprimer l\'intention "{name}" ? <br/> <ul class="pl-2 pt-2"> <li>La suppression est <b>irrévocable</b>.</li><li><b>Toutes les opportunités</b> liées seront <b>supprimées</b>.',
                title: 'Supprimer une intention <span class="text-caption text-medium-emphasis">{id}</span>',
            },
            due_status: {
                d1: '< 2 ans',
                d2: '2 à 4 ans',
                d3: '> à 4 ans',
                index: 'Statut',
            },
            statut: {
                d1: '< 2 ans',
                d2: '2 à 4 ans',
                d3: '> à 4 ans',
                index: 'Statut',
            },
            edit: 'Modifier l\'intention',
            geometry: 'Géométrie',
            id: 'Id',
            info: 'Précision',
            name: 'Nom',
            nom: 'Nom',
            precision: 'Précision',
            opportunity: 'Opportunité',
            organization_id: 'Structure',
            structure_id: 'Structure',
            my_organization_id: 'Structure',
            organization: {
                index: 'Structure',
                t: 'Structure',
            },
            qualification: {
                _1_new: 'À qualifier',
                _2_active: 'En cours',
                _3_done: 'Réalisé',
                _4_cancelled: 'Abandonné',
                index: 'Qualification',
            },
            status: {
                _1_new: 'À qualifier',
                _2_active: 'En cours',
                _3_done: 'Réalisé',
                _4_cancelled: 'Abandonné',
                index: 'Qualification',
            },
            current_status: {
                _1_new: 'À qualifier',
                _2_active: 'En cours',
                index: 'Qualification',
            },
            archive_status: {
                _3_done: 'Réalisé',
                _4_cancelled: 'Abandonné',
                index: 'Qualification',
            },
            toaster: {
                POST: { success: { title: 'Intentions', text: 'L\'intention a été créée' } },
                DELETE: {
                    success: {
                        text: 'L\'intention a été supprimée',
                        title: 'Intentions',
                    },
                },
                PUT: {
                    success: {
                        text: 'L\'intention {id} a été enregistrée',
                        title: 'Intentions',
                    },
                },
            },
            year: 'Année',
            annee: 'Année',

            imports: {
                index: 'Import des intentions',
                add: 'Ajouter un import',
                view: 'Détail de l\'import',
                edit: 'Validation de l\'import',
                delete: {
                    index: 'Supprimer le fichier',
                    title: 'Suppression de l\'import prêt',
                    text: 'Le fichier a été validé, mais <b>l\'import n\'a pas été fait</b>. <br>Souhaitez-vous supprimer définitivement ce fichier ?',
                },
                title: 'Titre de l\'import',
                file_name: 'Nom du fichier',
                status: {
                    index: 'Statut de l\'import',
                    validated: 'Pret à être importé',
                    done: 'Terminé',
                    error: 'Erreur lors de l\'import',
                },
                toaster: {
                    PUT: {
                        success: {
                            title: 'Import des intentions',
                            text: 'L\'import {title} a été fait',
                        },
                    },
                    DELETE: {
                        success: {
                            title: 'Import des intentions',
                            text: 'L\'import {title} a été supprimé',
                        },
                    },
                },
            },

        },
        opportunities: {
            custom_column: 'Actions',
            export: 'Exporter',
            like: {
                none: 'Aucune réponse',
                true: 'Acceptée',
                false: 'Refusée',
            },
            my_like: {
                value: {
                    none: 'Aucune réponse',
                    true: 'Acceptée',
                    false: 'Refusée',
                    index: 'Ma réponse',
                },
            },
            likes: 'Opportunités',
            commune: {
                index: 'Commune',
                insee: 'Commune',
            },
        },
        opportunity_processings: {
            toaster: {
                POST: { success: { title: 'Le calcul des opportunités a bien été effectué.', text: '' } },
            },
        },
    },
}
